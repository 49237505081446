import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const { data } = await useJwt.get(API_ROUTES.patient.get)

		return data
	},
	findPatientByNumberDocument(document_number) {
		const params = {
			order: 'asc',
			order_by: 'first_name',
			offset: 0,
			limit: 0,
			'filters[0][field]': 'document_number',
			'filters[0][operator]': '=',
			'filters[0][value]': document_number,
		}
		return useJwt.get(`${API_ROUTES.patient.get}`, params)
	},
	find(id) {
		return useJwt.get(`${API_ROUTES.patient.find}/${id}`)
	},
	history(id) {
		return useJwt.get(`${API_ROUTES.patient.find}/history/${id}`)
	},
	save(id, data) {
		return useJwt.put(`${API_ROUTES.patient.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.patient.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.update(`${API_ROUTES.patient.update}/${id}`, data)
	},
	async getAllByAreaDate(idArea, date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'area_id',
			'filters[0][operator]': '=',
			'filters[0][value]': idArea,
			'filters[1][field]': 'date',
			'filters[1][operator]': '=',
			'filters[1][value]': date,
		}
		const { data } = await useJwt.get(API_ROUTES.order.get, params)

		return data
	},
	async getAllExamByAreaDate(idArea, date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'area_id',
			'filters[0][operator]': '=',
			'filters[0][value]': idArea,
			'filters[1][field]': 'ordered_date',
			'filters[1][operator]': '=',
			'filters[1][value]': date,
		}
		const { data } = await useJwt.get(API_ROUTES.examByDate.get, params)

		return data
	},
	async getCallStatics(p) {
		const params = p
		const { data } = await useJwt.get(API_ROUTES.call.statics, params)
		return data
	},
	async getDietByPatient(patient_id) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'patient_id',
			'filters[0][operator]': '=',
			'filters[0][value]': patient_id,
		}
		const { data } = await useJwt.get(API_ROUTES.order.get, params)
		return data
	},

	async getCallData(p) {
		const params = p
		const { data } = await useJwt.get(API_ROUTES.call.data, params)
		return data
	},
}
